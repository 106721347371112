<template>
    <div>
      <!-- large devices  -->
      <v-navigation-drawer
        :mini-variant.sync="mini"
        app
        v-model="drawer"
        :permanent="drawer"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-list class="mt-1">
          <v-list-item class="px-2 pb-n1">
            <v-img
              v-if="mini"
              lazy-src="img/logo-mini.png"
              max-width="32"
              src="img/logo-mini.png"
            ></v-img>

            <v-img
              v-else
              lazy-src="img/logo-128.png"
              max-width="180"
              src="img/logo-128.png"
            ></v-img>
          </v-list-item>
        </v-list>
        <v-divider class="mt-n1"></v-divider>
        <v-list class="mt-1">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-icon color="primary">mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="text-wrap primary--text mx-auto font-weight-black">
              {{ org ? org.legal_name || org.name : user.name}}
            </v-list-item-title>
            <v-btn icon @click.stop="toggleDrawer(!mini)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider class="mb-n2"></v-divider>
        <v-list dense>
          <!-- ADMIN  -->
          <div v-if="1">
            <div v-for="item in organisationMenu" :key="item.title">
              <!-- START list group  -->
              <v-list-group v-if="item.child" :to="item.route" color="primary">
                <template v-slot:activator>
                  <v-list-item-icon>
                    <a dark small v-if="mini">
                      <v-icon :color="item.color" class="my-auto">
                        {{ item.icon }}
                      </v-icon>
                    </a>
                    <a :color="item.color" dark v-else>
                      <v-icon class="my-auto" :color="item.color" x-large>
                        {{ item.icon }}
                      </v-icon>
                    </a>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      :color="item.color"
                      :class="mini ? 'py-2' : 'py-5'"
                    >
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <!-- First child  -->
                <div v-if="item.child">
                  <v-list-item
                    v-for="(subItem, i) in item.child"
                    :key="i"
                    link
                    :to="subItem.route"
                    v-show="org"
                  >
                    <v-list-item-title
                      :color="subItem.color"
                      v-text="subItem.title"
                      class="ml-15"
                    >
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon v-text="subItem.icon"></v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <!-- Show only basic user My Profile -->
                  <v-list-item
                    v-for="(subItem, i) in item.child"
                    :key="i"
                    link
                    :to="subItem.route"
                    v-show="!org && subItem.title == 'My Profile'"
                  >
                    <v-list-item-title
                      :color="subItem.color"
                      v-text="subItem.title"
                      class="ml-15"
                    >
                    </v-list-item-title>
                    <v-list-item-icon>
                      <v-icon v-text="subItem.icon"></v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- END list group  -->
              <!-- START menu list item [has no child]  -->
              <v-list-item
                v-else-if="!item.child"
                link
                :to="item.route"
                :class="mini ? 'py-0' : 'py-5'"
                color="primary"
                :disabled="!org && item.title !='Dashboard'"
              >
                <v-list-item-icon>
                    <a :color="item.color" dark small v-if="mini">
                      <v-icon color="primary">{{ item.icon }}</v-icon>
                    </a>
                    <a :color="item.color" dark v-else>
                      <a :color="item.color" dark>
                        <v-icon class="mt-n2" color="primary" x-large>
                          {{ item.icon }}
                        </v-icon>
                      </a>
                    </a>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title :color="item.color">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- END menu list item  -->
            </div>
          </div>
          <!-- STANDARD -->
          <div v-else>
              <div v-for="item in basicMenu" :key="item.title">
                <!-- START list group  -->
                <v-list-group v-if="item.child" :to="item.route" color="primary">
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <a dark small v-if="mini">
                        <v-icon :color="item.color" class="my-auto">
                          {{ item.icon }}
                        </v-icon>
                      </a>
                      <a :color="item.color" dark v-else>
                        <v-icon class="my-auto" :color="item.color" x-large>
                          {{ item.icon }}
                        </v-icon>
                      </a>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        :color="item.color"
                        :class="mini ? 'py-2' : 'py-5'"
                      >
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <!-- First child  -->
                  <div v-if="item.child">
                    <v-list-item
                      v-for="(subItem, i) in item.child"
                      :key="i"
                      link
                      :to="subItem.route"
                      v-show="org"
                    >
                      <v-list-item-title
                        :color="subItem.color"
                        v-text="subItem.title"
                        class="ml-15"
                      >
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-icon v-text="subItem.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-list-item
                      v-for="(subItem, i) in item.child"
                      :key="i"
                      link
                      :to="subItem.route"
                      v-show="!org && subItem.title == 'My Profile'"
                    >
                      <v-list-item-title
                        :color="subItem.color"
                        v-text="subItem.title"
                        class="ml-15"
                      >
                      </v-list-item-title>
                      <v-list-item-icon>
                        <v-icon v-text="subItem.icon"></v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </div>
                </v-list-group>
                <!-- END list group  -->
                <!-- START menu list item [has no child]  -->
                <v-list-item
                  v-else-if="!item.child"
                  link
                  :to="item.route"
                  :class="mini ? 'py-0' : 'py-5'"
                  color="primary"
                  :disabled="!org && item.title !='Dashboard'"
                >
                  <v-list-item-icon>
                    <a :color="item.color" dark small v-if="mini">
                      <v-icon color="primary">{{ item.icon }}</v-icon>
                    </a>
                    <a :color="item.color" dark v-else>
                      <a :color="item.color" dark>
                        <v-icon class="mt-n2" color="primary" x-large>
                          {{ item.icon}}
                        </v-icon>
                      </a>
                    </a>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title :color="item.color">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- END menu list item  -->
              </div>
          </div>
          <v-divider></v-divider>
        </v-list>
      </v-navigation-drawer>

      <!-- small devices -->
      <v-navigation-drawer
        :mini-variant.sync="miniMin"
        app
        v-model="drawerMin"
        :permanent="drawer"
        v-else
      >
        <v-list class="mt-1">
          <v-list-item class="px-2 pb-n1">
            <v-img
              v-if="miniMin"
              lazy-src="img/logo-mini.png"
              max-width="32"
              src="img/logo-mini.png"
            ></v-img>
            <v-img
              v-else
              lazy-src="img/logo-128.png"
              max-width="180"
              src="img/logo-128.png"
            ></v-img>
          </v-list-item>
        </v-list>
        <v-divider class="mt-n1"></v-divider>
        <v-list class="mt-1">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-icon color="primary">mdi-domain</v-icon>
            </v-list-item-avatar>
            <v-list-item-title class="text-wrap text-primary mx-auto">
              {{ org ? org.legal_name || org.name : user.name }}
            </v-list-item-title>
            <v-btn icon @click.stop="toggleDrawer(!mini)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <!-- ADMIN -->
          <div>
            <div v-for="item in organisationMenu" :key="item.title">
              <!-- START list group [has child]  -->
              <v-list-group
                v-if="item.child"
                :to="item.route"
                color="info darken-1"
              >
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-btn small :color="item.color" fab dark class="mt-n2 ml-n2">
                      <v-icon color="white">{{ item.icon }}</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title :color="item.color" class="my-auto">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <!-- First child  -->
                <div v-if="item.child">
                  <v-list-item
                    v-for="(subItem, i) in item.child"
                    :key="i"
                    link
                    :to="subItem.route"
                    v-show="org"
                  >
                    <v-list-item-title
                      :color="subItem.color"
                      v-text="subItem.title"
                      class="ml-auto"
                    >
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(subItem, i) in item.child"
                    :key="i"
                    link
                    :to="subItem.route"
                    v-show="!org && subItem.title == 'My Profile'"
                  >
                    <v-list-item-title
                      :color="subItem.color"
                      v-text="subItem.title"
                      class="ml-auto"
                    >
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- END list group  -->

              <!-- START menu list item  -->
              <v-list-item
                v-else
                link
                :to="item.route"
                class="py-3"
                color="info darken-1"
                :disabled="!org && item.title != 'Dashboard'"
              >
                <v-list-item-icon>
                  <v-btn small :color="item.color" fab dark class="mt-n2 ml-n2">
                    <v-icon color="white">{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title :color="item.color">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- END menu list item  -->
            </div>
          </div>
          <v-divider></v-divider>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data: () => ({
        admins: [
          ["Management", "mdi-account-multiple-outline"],
          ["Settings", "mdi-cog-outline"],
        ],
        cruds: [
          ["Create", "mdi-plus-outline"],
          ["Read", "mdi-file-outline"],
          ["Update", "mdi-update"],
          ["Delete", "mdi-delete"],
        ],
        offset: true,
        basicMenu: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/dashboard",
            color: "primary",
            privilege: "view-dashboard",
          },
        ],
        organisationMenu: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard",
            route: "/dashboard",
            color: "primary",
            privilege: "view-dashboard",
          }, 
          {
            title: "Organizations",
            icon: "mdi-home-city",
            route: "/organisations",
            color: "primary",
            privilege: "view-all-tenders",
          },
          {
            title: "Facilities",
            icon: "mdi-bank",
            route: "/facility",
            color: "primary",
            privilege: "view-all-tenders",
          },
          {
            title: "Security Officers",
            icon: "mdi-account-tie",
            route: "/watchmen-schedule",
            color: "primary",
            privilege: "view-dashboard",
          },
          {
            title: "Reports",
            icon: "mdi-alert-octagon",
            route: "/reports",
            color: "primary",
            privilege: "view-settings",
          },
          {
            title: "SMS Logs",
            icon: "mdi-message-processing",
            route: "/sms-logs",
            color: "primary",
            privilege: "view-sms-logs",
          },
          {
            title: "Settings",
            icon: "mdi-cog-outline",
            route: "/settings",
            color: "primary",
            privilege: "view-settings",
            child: [
              {
                title: "Company profile",
                icon: "mdi-account-alert",
                route: "/company-profile",
                color: "primary",
                privilege: "company-profile",
              },
              {
                title: "My Profile",
                icon: "mdi-account",
                route: "/complete-profile",
                color: "primary",
                privilege: "complete-profile",
              },
              {
                title: "System Configurations",
                icon: "mdi-book-cog",
                route: "/system-configurations",
                color: "primary",
                privilege: "complete-profile",
              },
            ],
          },
        ],
    }),
    methods: {
      toggleDrawer(value) {
        this.$store.dispatch("minifyDrawer", value);
      },
      handleLogout() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
    },
    created() {
      this.$nextTick(() => {
        // location.reload();
      });
    },
    computed: {
      isAdmin() {
        return this.$store.state.auth.user.is_admin;
      },
      user() {
        return JSON.parse(localStorage.getItem("user"));
      },
      org() {
        return JSON.parse(localStorage.getItem("org"));
      },
      drawer: {
        get() {
          return this.$store.state.drawer;
        },
        set() {
          this.$store.dispatch("minifyDrawer", !this.drawer);
        },
      },
      drawerMin: {
        get() {
          return this.$store.state.drawer;
        },
        set() {
          this.$store.dispatch("minifyDrawer", !this.drawer);
        },
      },
      mini: {
        get() {
          return this.$store.state.mini;
        },
        set() {
          this.$store.dispatch("minifyDrawer", !this.mini);
        },
      },
      miniMin: {
        get() {
          return !this.$store.state.mini;
        },
        set() {
          !this.$store.dispatch("minifyDrawer", !this.mini);
        },
      },
    },
};
</script>
